<template>
  <div class="loading-overlay" v-if="loading">
    <div class="loader"></div>
  </div>
  <div class="orders" v-if="!loading">
    <div class="open-orders" v-if="$store.state.orders">
      <div class="orders-list">
        <!-- <div class="order">
          <div class="left">
            <div class="status yet-to-collect">Yet to be collected</div>
            <div class="info-row">
              <div class="info">
                <div class="title">Collection Time</div>
                <div class="content">
                  <div class="title">March 26, 2021</div>
                  <p>11:00am, March 26, 2021</p>
                </div>
              </div>

              <div class="info">
                <div class="title">Collection address:</div>
                <div class="content">
                  <div class="title">Office</div>
                  <p>
                    Mazaya Business Avenue BB2 Jumeirah Lakes Towers, 73463 Abu Dhabi, United Arab
                    Emirates
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="right">
            <div class="tracking-number dhl">
              JAGL43041651610942499745
            </div>

            <div class="info">
              <div class="title">Destination address:</div>
              <div class="content">
                <div class="title">Elizabeth Swann</div>
                <p>Studio 132 A1, NY 10019 New York, United States</p>
                <a href="#" class="btn details">Details</a>
              </div>
            </div>
          </div>
        </div> -->

        <!-- <div class="order">
          <div class="left">
            <div class="status collected">Collected</div>
            <div class="info-row">
              <div class="info delivery-date">
                <div class="title">Estimated Delivery:</div>
                <div class="content">
                  <div class="delivery-date">March 30, 2021</div>
                </div>
              </div>
            </div>
          </div>

          <div class="right">
            <div class="tracking-number dhl">
              JAGL43041651610942499745
            </div>

            <div class="info">
              <div class="title">Destination address:</div>
              <div class="content">
                <div class="title">Elizabeth Swann</div>
                <p>Studio 132 A1, NY 10019 New York, United States</p>
                <a href="#" class="btn details">Details</a>
              </div>
            </div>
          </div>
        </div> -->

        <OpenOrder :order="order" v-for="order in $store.state.orders" :key="order.id" />
      </div>
    </div>

    <div v-if="$store.state.orders && !$store.state.orders.length" class="no-content-message">
      <p>No orders yet.</p>
    </div>

    <!-- Delivered Orders : Temporarily commented -->
    <!-- <div class="delivered-orders">
      <div class="title-wrapper">
        <div class="left"></div>
        <div class="title">Delivered</div>
        <div class="right"></div>
      </div>

      <div class="orders-list">
        <div class="delivered-order">
          <div class="status">
            <div class="title">Delivered</div>
            <p>March 31, 2021 - 8:32am</p>
          </div>
          <div class="address">
            <div class="title">Elizabeth Swann</div>
            <p>New York, United States</p>
          </div>
          <div class="details">
            <router-link :to="{ path: '/order/' + 33 }" class="btn details">Details</router-link>
          </div>
        </div>

        <div class="delivered-order">
          <div class="status">
            <div class="title">Delivered</div>
            <p>March 31, 2021 - 8:32am</p>
          </div>
          <div class="address">
            <div class="title">Elizabeth Swann</div>
            <p>New York, United States</p>
          </div>
          <div class="details">
            <router-link :to="{ path: '/order/' + 33 }" class="btn details">Details</router-link>
          </div>
        </div>

        <div class="delivered-order">
          <div class="status">
            <div class="title">Delivered</div>
            <p>March 31, 2021 - 8:32am</p>
          </div>
          <div class="address">
            <div class="title">Elizabeth Swann</div>
            <p>New York, United States</p>
          </div>
          <div class="details">
            <router-link :to="{ path: '/order/' + 33 }" class="btn details">Details</router-link>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import OpenOrder from '@/components/orders/OpenOrder';

export default {
  name: 'OrdersList',
  components: { OpenOrder },
  data() {
    return {
      loading: false
    }
  },
  mounted() {
    this.loading = true;
    
    this.$store.dispatch('fetchOrders').then(() => {
      this.loading = false;
    });
  },
};
</script>

<style lang="scss">
/* @import '../../scss/partials/variables.scss'; */

/* Delivered Orders : Temporarily commented */
/* .delivered-orders {
  margin-bottom: 120px;
  .title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0px;

    .left,
    .right {
      border-top: solid 2px #e3dfeb;
      height: 2px;
      flex: 1;
    }

    .title {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
      letter-spacing: 1px;
      color: #d1cade;
      padding: 0px 30px;
      text-transform: uppercase;
    }
  }

  .delivered-order {
    display: flex;
    width: 100%;
    padding: 28px;
    border-radius: 5px;
    background-color: rgba(188, 184, 254, 0.14);
    align-items: center;
    margin-bottom: 25px;

    > div {
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #252423;
      }

      p {
        font-size: 12px;
        color: #252423;
        margin: 5px 0px 0px;
      }
    }

    .status {
      background-image: url($srcPath+'assets/yellow-delivered-icon.svg');
      background-position: left center;
      background-repeat: no-repeat;
      padding-left: 54px;
      flex: 0 0 260/744 * 100%;
      background-size: contain;
    }

    .address {
      flex: 1;
    }

    .details {
      .btn {
        padding: 9px 50px;
        border-radius: 18px;
        box-shadow: 0 2px 20px 0 rgba(147, 146, 173, 0.48);
        background-color: #9392ad;
        display: inline-block;
      }
    }
  }

  @media (max-width: 768px)
  {
    .delivered-order {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @media (max-width: 640px)
  {
    margin-bottom: 60px;

    .delivered-order {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .status {
        margin-bottom: 30px;
        flex: 1;
      }

      .address {
        margin-bottom: 30px;
        text-align: right;
      }

      .details {
        flex: 0 0 100%;
        text-align: right;
      }
    }
  }


  @media (max-width: 450px)
  {
    .delivered-order { 
      display: block;

      .address {
        text-align: left;
      }

      .details {
        text-align: left;
      }
    }
  }
} */
</style>
